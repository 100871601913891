import React from "react"
import { useMutation } from "@apollo/client"
import { Query } from "@apollo/client/react/components"
import { RouteChildrenProps } from "react-router"
import Typography from "@material-ui/core/Typography"
import TextField from "@material-ui/core/TextField"
import Switch from "@material-ui/core/Switch"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import FormGroup from "@material-ui/core/FormGroup"
import Divider from "@material-ui/core/Divider"
import Button from "@material-ui/core/Button"
import { useForm } from "react-hook-form"

import Layout from "../components/Layout"
import LoadingView from "../components/LoadingView"
import { DbRow } from "../../../common/db-typings"
import { ADMIN_REMOVE_EVENT_FROM_SLATE_MUTATION, ADMIN_SQL_QUERY } from "../queries"
import { AdminSQLQuery, AdminSQLQueryVariables } from "../../../__generated__/AdminSQLQuery"
import { adminRemoveEventFromSlate, adminRemoveEventFromSlateVariables } from "../../../__generated__/adminRemoveEventFromSlate"

const inputLabelProps = {
  shrink: true,
}

interface IRemovePicksFormProps {
  period: DbRow
  event: DbRow
  awayTeam?: DbRow
  homeTeam?: DbRow
  mutate: any
  isSaving: boolean
}

const RemovePicksForm: React.FC<IRemovePicksFormProps> = ({ period, event, awayTeam, homeTeam, mutate, isSaving }) => {
  const [deletePicks, setDeletePicks] = React.useState<boolean>(false)
  const [deletePeriodEvent, setDeletePeriodEvent] = React.useState<boolean>(false)
  const [notificationBannerHtml, setNotificationBannerHtml] = React.useState<string | undefined>(period.extra?.notificationBannerHtml || undefined)

  const { register, handleSubmit } = useForm()

  const onSubmit = async () => {
    // const { deletePicks, deletePeriodEvent, notificationBannerHtml } = data

    await mutate({
      variables: {
        periodId: period.id,
        eventId: event.id,
        deletePicks: deletePicks,
        deletePeriodEvent: deletePeriodEvent,
        notificationBannerHtml: notificationBannerHtml,
      },
    })
  }

  const handleSetDeletePicks = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDeletePicks(!!event.target.checked)
  }

  const handleSetDeletePeriodEvent = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDeletePeriodEvent(!!event.target.checked)
  }

  const handleNotificationBannerHtml = (event: React.ChangeEvent<HTMLInputElement>) => {
    const notificationBannerHtml = event?.target?.value
    setNotificationBannerHtml(notificationBannerHtml)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Typography component="h5">
        Event - {awayTeam?.abbrev} @ {homeTeam?.abbrev}
      </Typography>
      <FormGroup row={true}>
        <FormControlLabel
          control={
            <Switch
              checked={!!deletePicks}
              onChange={handleSetDeletePicks}
              value={deletePicks}
              name={`deletePicks`}
              color="primary"
              inputRef={register}
            />
          }
          label="Delete Picks"
        />
      </FormGroup>
      <FormGroup row={true}>
        <FormControlLabel
          control={
            <Switch
              checked={!!deletePeriodEvent}
              onChange={handleSetDeletePeriodEvent}
              value={deletePeriodEvent}
              name={`deletePeriodEvent`}
              color="primary"
              inputRef={register}
            />
          }
          label="Delete PeriodEvent"
        />
      </FormGroup>
      <TextField
        id="notificationBannerHtml"
        label="Notification Banner notificationBannerHtml"
        type="text"
        className={undefined}
        InputLabelProps={inputLabelProps}
        value={notificationBannerHtml}
        onChange={handleNotificationBannerHtml}
        margin="normal"
        fullWidth={true}
        name={"notificationBannerHtml"}
        inputRef={register}
      />
      <Button variant="contained" color="primary" disabled={isSaving} type="submit">
        {isSaving ? `Saving...` : `Save`}
      </Button>
    </form>
  )
}

const RemoveEventFromSlate: React.FC<RouteChildrenProps> = ({ location, history }) => {
  const [eventId, setEventId] = React.useState<number | undefined>(undefined)
  const [periodId, setPeriodId] = React.useState<number | undefined>(undefined)

  const handleSetPeriodId = (event: React.ChangeEvent<HTMLInputElement>) => {
    const periodId = Number(event?.target?.value)
    setPeriodId(periodId)
  }

  const handleSetEventId = (event: React.ChangeEvent<HTMLInputElement>) => {
    const eventId = Number(event?.target?.value)
    setEventId(eventId)
  }

  const [mutate, mutationResult] = useMutation<adminRemoveEventFromSlate, adminRemoveEventFromSlateVariables>(ADMIN_REMOVE_EVENT_FROM_SLATE_MUTATION)

  return (
    <Layout title={`Scripts - Remove Game From Picks And Slate`}>
      <Typography component="h5">Set Period and Event Ids</Typography>
      <FormGroup row={true}>
        <TextField
          id="eventId"
          label="Event DB ID"
          type="number"
          className={undefined}
          onChange={handleSetEventId}
          margin="normal"
          name={"eventId"}
          size={"medium"}
          required={true}
        />
      </FormGroup>
      <FormGroup row={true}>
        <TextField
          id="periodId"
          label="Period DB ID"
          type="number"
          className={undefined}
          onChange={handleSetPeriodId}
          margin="normal"
          name={"periodId"}
          size={"medium"}
          required={true}
        />
      </FormGroup>
      <Divider light={true} style={{ margin: "40px" }} />
      {periodId && eventId && (
        <Query<AdminSQLQuery, AdminSQLQueryVariables>
          query={ADMIN_SQL_QUERY}
          variables={{ statement: `SELECT * from PeriodEvent where periodId = ${periodId} and eventId = ${eventId}` }}
        >
          {(periodEventsQuery) => {
            const periodEvents = periodEventsQuery?.data?.sql
            return (
              <>
                {(Boolean(periodEvents?.length) && (
                  <Query<AdminSQLQuery, AdminSQLQueryVariables>
                    query={ADMIN_SQL_QUERY}
                    variables={{ statement: `SELECT * from Period where Period.id = ${periodId}` }}
                  >
                    {(periodQuery) => {
                      const periods = periodQuery?.data?.sql
                      const period = periods?.find((p) => p.id === periodId)
                      return (
                        <>
                          {(period && (
                            <Query<AdminSQLQuery, AdminSQLQueryVariables>
                              query={ADMIN_SQL_QUERY}
                              variables={{ statement: `SELECT * from Event where Event.id = ${eventId}` }}
                            >
                              {(eventsQuery) => {
                                const event = eventsQuery.data?.sql?.find((e) => e.id === eventId)
                                return (
                                  <>
                                    {(event && (
                                      <Query<AdminSQLQuery, AdminSQLQueryVariables>
                                        query={ADMIN_SQL_QUERY}
                                        variables={{
                                          statement: `SELECT * from Team where Team.id = ${event.awayTeamId} or Team.id = ${event.homeTeamId}`,
                                        }}
                                      >
                                        {(teamsQuery) => {
                                          const teams = teamsQuery?.data?.sql
                                          const awayTeam = teams?.find((t) => t.id === event.awayTeamId)
                                          const homeTeam = teams?.find((t) => t.id === event.homeTeamId)
                                          return (
                                            <>
                                              {homeTeam && awayTeam && (
                                                <RemovePicksForm
                                                  period={period}
                                                  event={event}
                                                  awayTeam={awayTeam}
                                                  homeTeam={homeTeam}
                                                  mutate={mutate}
                                                  isSaving={mutationResult.loading}
                                                />
                                              )}
                                              {teamsQuery.loading && <LoadingView />}
                                            </>
                                          )
                                        }}
                                      </Query>
                                    )) || <Typography component="h6">Please enter an Event Id</Typography>}
                                    {eventsQuery.loading && <LoadingView />}
                                  </>
                                )
                              }}
                            </Query>
                          )) || <Typography component="h6">Please enter a Period Id</Typography>}
                          {periodQuery.loading && <LoadingView />}
                        </>
                      )
                    }}
                  </Query>
                )) || <Typography component="h6">No period events found. Please make sure the values are correct.</Typography>}
                {periodEventsQuery.loading && <LoadingView />}
              </>
            )
          }}
        </Query>
      )}
    </Layout>
  )
}

export default RemoveEventFromSlate
